import { RichTextP } from "@hex/common";
import {
  KeyboardHandler,
  createPluginFactory,
  deleteBackward,
  getBlockAbove,
  getEditorString,
  insertNodes,
  someNode,
} from "@udecode/plate-common";

import { Keys } from "../../../../util/Keys";

const PLUGIN_KEY = "exitBreakDoubleEnter";

interface ExitBreakOnDoubleEnterPlugin {
  allowedTypes: readonly string[];
}

const onKeyDown: KeyboardHandler<ExitBreakOnDoubleEnterPlugin> =
  (editor, { options: { allowedTypes = [] } }) =>
  (evt) => {
    if (
      evt.key === Keys.ENTER &&
      someNode(editor, { match: { type: allowedTypes } })
    ) {
      const path = getBlockAbove(editor)?.[1];
      if (getEditorString(editor, path).endsWith("\n")) {
        deleteBackward(editor);
        insertNodes(editor, {
          type: RichTextP.value,
          children: [{ text: "" }],
        });

        evt.preventDefault();
        evt.stopPropagation();
        return;
      }
    }
  };

/**
 * This plugin exits out of the current element type if the user
 * presses enter twice in a row, for specified element types.
 * Use cases: existing out of blockquote
 */
export const createExitBreakOnDoubleEnterPlugin =
  createPluginFactory<ExitBreakOnDoubleEnterPlugin>({
    key: PLUGIN_KEY,
    handlers: {
      onKeyDown: onKeyDown,
    },
    options: {
      allowedTypes: [],
    },
  });
