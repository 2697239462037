import { PopoverTargetProps } from "@blueprintjs/core";
import { ReferenceElement, uuid } from "@hex/common";
import { findNodePath, setNodes, useEditorRef } from "@udecode/plate-common";
import React, { useCallback, useState } from "react";
import { useReadOnly, useSelected } from "slate-react";
import styled from "styled-components";

import {
  HexButton,
  HexInputGroup,
  HexTooltip,
} from "../../../../hex-components";
import { Keys } from "../../../../util/Keys";
import { TRenderCustomElementProps } from "../../elementRenderers.js";

import { useRichTextReferenceContext } from "./RichTextReferenceContext";

const StyledCodePill = styled.span<{
  $clickable: boolean;
  $selected: boolean;
}>`
  ${({ theme }) => theme.pill.COBALT.css}
  ${({ $clickable }) => ($clickable ? "cursor: pointer;" : "")}

  height: auto;
  padding: 0 4px;

  line-height: 1.35em;

  box-shadow: none;
`;

const TooltipContent = styled.div`
  display: flex;
  align-items: center;
`;

const TooltipButton = styled(HexButton)`
  margin-left: 4px;
`;

export function RichTextReferenceElement(
  props: TRenderCustomElementProps,
): JSX.Element {
  const { attributes, children, element } = props;
  const editorRef = useEditorRef();
  const { computedReferences, isLogicView } = useRichTextReferenceContext();
  const [showVariable, setShowVariable] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const isReadOnly = useReadOnly();

  // returns whether the element is in current selection
  // each rendered element is wrapped by a isSelection context provider by slate
  const isSelected = useSelected();

  const computedValue = ReferenceElement.guard(element)
    ? computedReferences[element.id]
    : null;
  const hasComputedValue = computedValue != null;
  const isComputedValueShown = !showVariable && hasComputedValue;
  const shouldShowRawComputedValue = !isLogicView && isComputedValueShown;

  // need to render custom target as we need to make the tooltip target
  // non-contenteditable as it swallows up selection in chrome
  const renderTarget = useCallback(
    (targetProps: PopoverTargetProps) => {
      const { isOpen: _isOpen, ...rest } = targetProps;
      if (!ReferenceElement.guard(element)) {
        return <span {...attributes}>{children}</span>;
      }

      return (
        <span contentEditable={false} {...rest}>
          <StyledCodePill
            {...attributes}
            $clickable={hasComputedValue}
            $selected={isSelected}
            contentEditable={false}
            // eslint-disable-next-line react/jsx-no-bind
            onClick={() => {
              if (hasComputedValue) {
                setShowVariable((value) => !value);
              }
            }}
          >
            {isComputedValueShown ? computedValue : element.value}
            {children}
          </StyledCodePill>
        </span>
      );
    },
    [
      attributes,
      children,
      computedValue,
      element,
      hasComputedValue,
      isComputedValueShown,
      isSelected,
    ],
  );

  const onReferenceEditorChange = useCallback(
    (value: string) => {
      const path = findNodePath(editorRef, element);
      setNodes(editorRef, { value, id: uuid() }, { at: path });
      setIsEditing(false);
      setShowVariable(false);
    },
    [editorRef, element],
  );

  const onReferenceEditorEscape = useCallback(() => {
    setIsEditing(false);
    setIsTooltipOpen(false);
  }, []);

  const onTooltipInteraction = useCallback(
    (isOpen: boolean) => {
      if (!isEditing) {
        setIsTooltipOpen(isOpen);
      }
    },
    [isEditing],
  );

  if (!ReferenceElement.guard(element)) {
    return <span {...attributes}>{children}</span>;
  }

  if (shouldShowRawComputedValue) {
    return (
      <span {...attributes} contentEditable={false}>
        {computedValue}
        {children}
      </span>
    );
  }

  const tooltipText = isComputedValueShown
    ? element.value
    : hasComputedValue
      ? "Click to see the computed value"
      : "Run cell to see computed value";

  return (
    <HexTooltip
      content={
        isEditing ? (
          <ReferenceEditor
            initialValue={element.value}
            onChange={onReferenceEditorChange}
            onEscape={onReferenceEditorEscape}
          />
        ) : (
          <TooltipContent>
            {tooltipText}
            <TooltipButton
              disabled={isReadOnly}
              minimal={true}
              small={true}
              // eslint-disable-next-line react/jsx-no-bind
              onClick={() => {
                setIsEditing(true);
                setShowVariable(true);
              }}
            >
              Edit
            </TooltipButton>
          </TooltipContent>
        )
      }
      inheritDarkTheme={false}
      interactionKind="hover"
      isOpen={isTooltipOpen}
      placement="bottom"
      renderTarget={renderTarget}
      onInteraction={onTooltipInteraction}
    />
  );
}

function ReferenceEditor({
  initialValue,
  onChange: onChangeProp,
  onEscape,
}: {
  initialValue: string;
  onChange: (value: string) => void;
  onEscape: () => void;
}): JSX.Element {
  const [value, setValue] = useState(initialValue);

  const onChange = useCallback(
    (evt: React.SyntheticEvent<HTMLInputElement>) => {
      setValue(evt.currentTarget.value);
    },
    [],
  );

  const onKeyDown = useCallback(
    (evt: React.KeyboardEvent) => {
      if (evt.key === Keys.ENTER) {
        onChangeProp(value);
      } else if (evt.key === Keys.ESCAPE) {
        onEscape();
      }
    },
    [onChangeProp, onEscape, value],
  );

  return (
    <HexInputGroup
      autoFocus={true}
      value={value}
      onBlur={onEscape}
      onChange={onChange}
      onKeyDown={onKeyDown}
    />
  );
}
