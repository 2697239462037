import { RichTextComputedReferences } from "@hex/common";
import React, { useContext } from "react";

export interface RichTextReferenceContext {
  computedReferences: RichTextComputedReferences;
  isLogicView: boolean;
}

export const RichTextReferenceContext =
  React.createContext<RichTextReferenceContext>({
    computedReferences: {},
    isLogicView: true,
  });

export function useRichTextReferenceContext(): RichTextReferenceContext {
  return useContext(RichTextReferenceContext);
}
