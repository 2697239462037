import { RichTextHR } from "@hex/common";
import { createPluginFactory } from "@udecode/plate-common";

import { HRRenderer } from "../../elementRenderers";

export const createHorizontalRulePlugin = createPluginFactory({
  key: RichTextHR.value,
  isElement: true,
  isVoid: true,
  type: RichTextHR.value,
  component: HRRenderer,
  deserializeHtml: {
    rules: [
      {
        validNodeName: "HR",
      },
    ],
  },
});
