import {
  RichTextBlockquote,
  RichTextCodeblock,
  RichTextElementType,
  RichTextH1,
  RichTextH2,
  RichTextH3,
  RichTextH4,
  RichTextH5,
  RichTextH6,
  RichTextHR,
  RichTextHeading,
  RichTextLI,
  RichTextMarkBold,
  RichTextMarkCode,
  RichTextMarkItalic,
  RichTextMarkStrikethrough,
  RichTextMarkUnderline,
  RichTextOL,
  RichTextP,
  RichTextPLegacy,
  RichTextParagraph,
  RichTextUL,
} from "@hex/common";
import {
  AutoformatBlockRule,
  AutoformatRule,
  createAutoformatPlugin,
} from "@udecode/plate-autoformat";
import {
  createExitBreakPlugin,
  createSoftBreakPlugin,
} from "@udecode/plate-break";
import {
  PlateEditor,
  PlatePlugin,
  PlatePluginComponent,
  TEditor,
  getParentNode,
  insertNodes,
  isElement,
  isSelectionAtBlockStart,
  setNodes,
} from "@udecode/plate-common";
import { createHeadingPlugin } from "@udecode/plate-heading";
import { createIndentPlugin } from "@udecode/plate-indent";
import { createListPlugin, toggleList, unwrapList } from "@udecode/plate-list";
import { createResetNodePlugin } from "@udecode/plate-reset-node";

import { Keys } from "../../../util/Keys";
import {
  BlockquoteRenderer,
  CodeblockRenderer,
  HeadingElementRenderer,
  ListItemRenderer,
  OrderedListRenderer,
  UnorderedListRenderer,
} from "../elementRenderers";

import { createDedentOnBackspacePlugin } from "./element/createDedentOnBackspacePlugin";
import { createExitBreakOnDoubleEnterPlugin } from "./element/createExitBreakOnDoubleEnterPlugin";
import { createHorizontalRulePlugin } from "./element/createHorizontalRulePlugin";

export const RICH_TEXT_HEADING_PLUGIN_KEY = "heading";
export const RICH_TEXT_LIST_PLUGIN_KEY = "list";

export function createElementPlugins(): readonly PlatePlugin[] {
  return [
    createHeadingPlugin({
      key: RICH_TEXT_HEADING_PLUGIN_KEY,
    }),
    createListPlugin({
      key: RICH_TEXT_LIST_PLUGIN_KEY,
    }),
    createHorizontalRulePlugin(),
    // resets node when backspacing from start of line
    createResetNodePlugin({
      options: {
        rules: [
          {
            types: [
              ...RichTextHeading.alternatives.map((heading) => heading.value),
              RichTextBlockquote.value,
              RichTextCodeblock.value,
            ],
            defaultType: RichTextP.value,
            hotkey: Keys.BACKSPACE,
            predicate: isSelectionAtBlockStart,
          },
        ],
      },
    }),
    createExitBreakOnDoubleEnterPlugin({
      options: {
        allowedTypes: [RichTextBlockquote.value],
      },
    }) as PlatePlugin<Record<string, unknown>>,
    // resets node when pressing enter, for headings
    createExitBreakPlugin({
      options: {
        rules: [
          {
            hotkey: Keys.ENTER,
            query: {
              start: true,
              end: true,
              allow: [
                ...RichTextHeading.alternatives.map((heading) => heading.value),
                RichTextCodeblock.value,
              ],
            },
          },
        ],
      },
    }),
    createSoftBreakPlugin({
      options: {
        rules: [
          {
            hotkey: Keys.ENTER,
            query: {
              allow: [RichTextBlockquote.value],
            },
          },
          {
            hotkey: "shift+enter",
          },
          {
            hotkey: "option+enter",
          },
        ],
      },
    }),
    createIndentPlugin({
      inject: {
        props: {
          validTypes: [RichTextP.value, RichTextPLegacy.value],
        },
      },
      plugins: [createDedentOnBackspacePlugin()],
    }),
    // markdown shortcuts
    createAutoformatPlugin({
      options: {
        rules: [...autoformatBlocks, ...autoformatLists, ...autoformatMarks],
      },
    }),
  ];
}

export function createElementPluginsComponents(): Record<
  Exclude<RichTextElementType, RichTextParagraph>,
  PlatePluginComponent
> {
  return {
    [RichTextH1.value]: HeadingElementRenderer,
    [RichTextH2.value]: HeadingElementRenderer,
    [RichTextH3.value]: HeadingElementRenderer,
    [RichTextH4.value]: HeadingElementRenderer,
    [RichTextH5.value]: HeadingElementRenderer,
    [RichTextH6.value]: HeadingElementRenderer,
    [RichTextUL.value]: UnorderedListRenderer,
    [RichTextOL.value]: OrderedListRenderer,
    [RichTextLI.value]: ListItemRenderer,
    [RichTextBlockquote.value]: BlockquoteRenderer,
    [RichTextCodeblock.value]: CodeblockRenderer,
  };
}

// ==================================================== //
// utility functions below taken from plate source code
// ==================================================== //
const clearBlockFormat: AutoformatBlockRule["preFormat"] = (editor) =>
  unwrapList(editor as PlateEditor);

const format = (editor: TEditor, customFormatting: () => void): void => {
  if (editor.selection != null) {
    const parentEntry = getParentNode(editor, editor.selection);
    if (!parentEntry) return;
    const [node] = parentEntry;
    if (isElement(node)) {
      customFormatting();
    }
  }
};

const formatList = (editor: TEditor, elementType: string): void => {
  format(editor, () =>
    toggleList(editor as PlateEditor, {
      type: elementType,
    }),
  );
};

// ==================================================== //

const autoformatBlocks: readonly AutoformatRule[] = [
  {
    mode: "block",
    type: RichTextH1.value,
    match: "# ",
    preFormat: clearBlockFormat,
  },
  {
    mode: "block",
    type: RichTextH2.value,
    match: "## ",
    preFormat: clearBlockFormat,
  },
  {
    mode: "block",
    type: RichTextH3.value,
    match: "### ",
    preFormat: clearBlockFormat,
  },
  {
    mode: "block",
    type: RichTextH4.value,
    match: "#### ",
    preFormat: clearBlockFormat,
  },
  {
    mode: "block",
    type: RichTextH5.value,
    match: "##### ",
    preFormat: clearBlockFormat,
  },
  {
    mode: "block",
    type: RichTextH6.value,
    match: "##### ", // Same as H5
    preFormat: clearBlockFormat,
  },
  {
    mode: "block",
    type: RichTextBlockquote.value,
    match: "> ",
    preFormat: clearBlockFormat,
  },
  {
    mode: "block",
    type: RichTextHR.value,
    match: "---",
    preFormat: clearBlockFormat,
    format: (editor) => {
      setNodes(editor, { type: RichTextHR.value });
      insertNodes(editor, {
        type: RichTextP.value,
        children: [{ text: "" }],
      });
    },
  },
  {
    mode: "block",
    type: RichTextCodeblock.value,
    match: "```",
    preFormat: clearBlockFormat,
  },
];

const autoformatLists: readonly AutoformatRule[] = [
  {
    mode: "block",
    type: RichTextLI.value,
    match: ["* ", "- "],
    preFormat: clearBlockFormat,
    format: (editor) => formatList(editor, RichTextUL.value),
  },
  {
    mode: "block",
    type: RichTextLI.value,
    match: ["1. ", "1) "],
    preFormat: clearBlockFormat,
    format: (editor) => formatList(editor, RichTextOL.value),
  },
];

const autoformatMarks: readonly AutoformatRule[] = [
  {
    mode: "mark",
    type: RichTextMarkBold.value,
    match: "**",
  },
  {
    mode: "mark",
    type: RichTextMarkBold.value,
    match: "*",
  },
  {
    mode: "mark",
    type: RichTextMarkUnderline.value,
    match: "__",
  },
  {
    mode: "mark",
    type: RichTextMarkItalic.value,
    match: "_",
  },
  {
    mode: "mark",
    type: RichTextMarkStrikethrough.value,
    match: "~~",
  },
  {
    mode: "mark",
    type: RichTextMarkCode.value,
    match: "`",
  },
];
