import {
  CustomElement,
  RichTextHeading,
  convertRichTextToPlainText,
} from "@hex/common";
import { isText } from "@udecode/plate-common";
import Slugger from "github-slugger"; // use the same slug creation lib as markdown

export function getRichTextHeadingUrl(element: CustomElement): string | null {
  if (isText(element) || !RichTextHeading.guard(element.type)) {
    return null;
  }

  const value = convertRichTextToPlainText([element]);
  if (value.trim().length === 0) {
    return null;
  }

  const slug = new Slugger().slug(value);
  return `#${slug}`;
}
