import {
  Decorate,
  PlatePluginComponent,
  createPluginFactory,
  isText,
} from "@udecode/plate-common";
import { rgba } from "polished";
import React from "react";
import type { Range } from "slate";
import styled from "styled-components";

import { ProjectSearchState } from "../../../redux/slices/logicViewSlice.js";
import {
  REGEX_GLOBAL_ALL_MATCHES,
  REGEX_GLOBAL_CAPS_MATCHES,
  SEARCH_MAX_OCCURRENCES,
  cleanSearchTerm,
} from "../../sidebar/project-search/utils.js";

export type SearchHighlightProps = {
  getSearchState: () => ProjectSearchState | null;
};

export const SEARCH_HIGHLIGHT_KEY = "search_highlight";

type SearchRange = {
  search: string;
} & Range;

const HighlightedMatch = styled.span`
  background-color: ${({ theme }) => rgba(theme.intent.PRIMARY, 0.1)};
`;

/**
 * Plate decorations are used to apply "decorations" to text ranges without modifying the content of the editor.
 * This is desirable (as opposed to marks) because decorations don't modify the editor contents and state.
 *
 * We use the decorateSearchHighlight function below to find all of the ranges
 * that should be decorated with highlighted text.
 */
export const decorateSearchHighlight: Decorate<SearchHighlightProps> =
  (_, plugin) =>
  ([node, path]) => {
    const { type } = plugin;
    const projectSearchState = plugin.options.getSearchState();
    const ranges: SearchRange[] = [];
    if (
      !projectSearchState ||
      projectSearchState.projectSearchTerm == null ||
      projectSearchState.projectSearchTerm.length === 0 ||
      !isText(node)
    ) {
      return ranges;
    }

    const {
      caseMatch,
      projectSearchTerm: search,
      wholeWordMatch,
    } = projectSearchState;

    const { text } = node;

    const cleanedSearchTerm = cleanSearchTerm(search, wholeWordMatch);
    const flags = caseMatch
      ? REGEX_GLOBAL_CAPS_MATCHES
      : REGEX_GLOBAL_ALL_MATCHES;
    const regex = new RegExp(cleanedSearchTerm, flags);

    let match;
    while (
      (match = regex.exec(text)) != null &&
      ranges.length < SEARCH_MAX_OCCURRENCES
    ) {
      if (regex.lastIndex === match.index) {
        regex.lastIndex++; // Avoid infinite loop for zero-length matches
      }

      ranges.push({
        anchor: { path, offset: match.index },
        focus: { path, offset: match.index + search.length },
        search,
        [type]: true,
      });
    }

    return ranges;
  };

const HighlightedTextComponent: PlatePluginComponent = ({ children, leaf }) => {
  const isSearchHighlight = leaf != null ? leaf[SEARCH_HIGHLIGHT_KEY] : false;
  return isSearchHighlight ? (
    <HighlightedMatch>{children}</HighlightedMatch>
  ) : (
    <>{children}</>
  );
};

/**
 * Plate custom plugin for decorating project search highights. This plugin was inspired by
 * the FindAndReplace plugin from Plate: https://github.com/udecode/plate/tree/main/packages/find-replace/src.
 * We customized the implementation to instead use a Redux search value for highlighting and included a component
 * that styles the highlight texted.
 */
export const createHighlightSearchPlugin =
  createPluginFactory<SearchHighlightProps>({
    decorate: decorateSearchHighlight,
    isLeaf: true,
    key: SEARCH_HIGHLIGHT_KEY,
  });

/**
 * Mapping from the Plate plugin key to React implementation for how to decorate the selected ranges.
 */
export function createSearchHighlightComponent(): Record<
  string,
  PlatePluginComponent
> {
  return {
    [SEARCH_HIGHLIGHT_KEY]: HighlightedTextComponent,
  };
}
