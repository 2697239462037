import {
  KeyboardHandler,
  createPluginFactory,
  getNodeEntries,
  isSelectionAtBlockStart,
} from "@udecode/plate-common";
import { outdent } from "@udecode/plate-indent";

import { Keys } from "../../../../util/Keys";

const onKeyDown: KeyboardHandler = (editor) => (e) => {
  if (e.key === Keys.BACKSPACE && isSelectionAtBlockStart(editor)) {
    const nodes = Array.from(
      getNodeEntries(editor, {
        block: true,
      }),
    );
    const hasIndent = nodes.some(([node]) => "indent" in node);
    if (hasIndent) {
      outdent(editor);
      e.preventDefault();
      e.stopPropagation();
    }
  }
};

export const createDedentOnBackspacePlugin = createPluginFactory({
  key: "depent_on_backspace",
  handlers: {
    onKeyDown: onKeyDown,
  },
});
