import { nullthrows } from "@hex/common";
import React, { useCallback, useContext, useMemo, useState } from "react";

import { RichTextToolbarMode } from "./toolbar/RichTextToolbarMode";

export interface PlateRichTextContextState {
  toolbarMode: RichTextToolbarMode;
}

export type PlateRichTextContext = [
  PlateRichTextContextState,
  React.Dispatch<React.SetStateAction<PlateRichTextContextState>>,
];

const INITIAL_VALUE: PlateRichTextContextState = {
  toolbarMode: RichTextToolbarMode.NONE,
};

const PlateRichTextContext = React.createContext<PlateRichTextContext | null>(
  null,
);

export interface PlateRichTextContextProviderProps {
  children: JSX.Element;
}

export const PlateRichTextContextProvider: React.ComponentType<PlateRichTextContextProviderProps> =
  React.memo(function PlateRichTextContextProvider({
    children,
  }: PlateRichTextContextProviderProps) {
    const [context, setContext] =
      useState<PlateRichTextContextState>(INITIAL_VALUE);
    const value: PlateRichTextContext = useMemo(() => {
      return [context, setContext];
    }, [context]);
    return (
      <PlateRichTextContext.Provider value={value}>
        {children}
      </PlateRichTextContext.Provider>
    );
  });

export function usePlateRichTextContext(): PlateRichTextContext {
  const context = useContext(PlateRichTextContext);
  return nullthrows(
    context,
    "Tried to access PlateRichTextContext but the context value wasn't defined",
  );
}

export function usePlateRichTextToolbarMode(): readonly [
  RichTextToolbarMode,
  (mode: RichTextToolbarMode) => void,
] {
  const [context, setContext] = usePlateRichTextContext();
  const setToolbarMode = useCallback(
    (toolbarMode: RichTextToolbarMode) => {
      setContext((c) => ({
        ...c,
        toolbarMode,
      }));
    },
    [setContext],
  );

  const value = useMemo(
    () => [context.toolbarMode, setToolbarMode] as const,
    [context.toolbarMode, setToolbarMode],
  );

  return value;
}
