import {
  RichTextMarkBold,
  RichTextMarkCode,
  RichTextMarkItalic,
  RichTextMarkStrikethrough,
  RichTextMarkUnderline,
} from "@hex/common";
import {
  createBoldPlugin,
  createCodePlugin,
  createItalicPlugin,
  createStrikethroughPlugin,
  createUnderlinePlugin,
} from "@udecode/plate-basic-marks";
import { PlatePlugin } from "@udecode/plate-common";
import { createLinkPlugin } from "@udecode/plate-link";

import { LinkRenderer } from "../elementRenderers";

export function createMarkPlugins(): readonly PlatePlugin[] {
  return [
    createLinkPlugin({
      // Overriding `component` here protects us from https://nvd.nist.gov/vuln/detail/CVE-2023-34245
      // until we can upgrade to a newer version of `@udecode/plate-link`.
      component: LinkRenderer,
    }),
    createBoldPlugin({
      type: RichTextMarkBold.value,
    }),
    createItalicPlugin({
      type: RichTextMarkItalic.value,
    }),
    createUnderlinePlugin({
      type: RichTextMarkUnderline.value,
    }),
    createStrikethroughPlugin({
      type: RichTextMarkStrikethrough.value,
    }),
    createCodePlugin({
      type: RichTextMarkCode.value,
    }),
  ];
}
