import {
  ELEMENT_DEFAULT,
  PlatePlugin,
  createPluginFactory,
} from "@udecode/plate-common";

import { ParagraphElementRenderer } from "../elementRenderers";

const ELEMENT_PARAGRAPH = "paragraph";

// override default element to render <p> tag
const createDefaultElementPlugin = createPluginFactory({
  key: ELEMENT_DEFAULT,
  isElement: true,
  component: ParagraphElementRenderer,
});

// support back-compat with pre-plate data
const createParagraphPlugin = createPluginFactory({
  key: ELEMENT_PARAGRAPH,
  isElement: true,
  component: ParagraphElementRenderer,
  // Taken from the upstream https://github.com/udecode/plate/blob/889b58ce09c93c78da9c104d5617887f4842698a/packages/paragraph/src/createParagraphPlugin.ts#L11-L18
  deserializeHtml: {
    // I believe the upstream added this as a hack for making fixed-width text work better, feels a little incomplete though
    query: (el) => el.style.fontFamily !== "Consolas",
    rules: [
      {
        validNodeName: "P",
      },
    ],
  },
});

export function createBasicPlugins(): readonly PlatePlugin[] {
  return [createDefaultElementPlugin(), createParagraphPlugin()];
}
